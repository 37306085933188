import { Business } from '../backend/db/all-entities';

interface BaseCategory {
  /**
   * Simple category name (title case). "I am looking for... ${name}"
   */
  name: string;
  /**
   * Category available at /browse/${path} (snake case, plural). This is used as the identifying piece of information in the database.
   */
  slug: string;
  /**
   * What kind of category this is
   */
  type: 'offer' | 'business';
  /**
   * Whether or not this category requires a matching qualification to post to
   */
  requiresQualification: boolean;
}

export type OfferCategory = BaseCategory & {
  type: 'offer';
};

export type BusinessCategory = BaseCategory & {
  type: 'business';
  businessTypeMustBe?: Business['type'];
};

export type Category = OfferCategory | BusinessCategory;

export const CATEGORIES: Category[] = [
  {
    name: 'Gyms',
    slug: 'gyms',
    type: 'business',
    requiresQualification: false,
    businessTypeMustBe: 'company'
  },
  {
    name: 'Training',
    slug: 'training',
    type: 'offer',
    requiresQualification: true
  },
  {
    name: 'Equipment',
    slug: 'equipment',
    type: 'offer',
    requiresQualification: false
  },
  {
    name: 'Nutrition',
    slug: 'nutrition',
    type: 'offer',
    requiresQualification: false
  },
  {
    name: 'Apparel',
    slug: 'apparel',
    type: 'offer',
    requiresQualification: false
  },
  {
    name: 'Photography',
    slug: 'photography',
    type: 'offer',
    requiresQualification: false
  },
  {
    name: 'Local Events',
    slug: 'local-events',
    type: 'offer',
    requiresQualification: false
  }
];

export const businessCategoryIsAllowed = (category: BusinessCategory, type: Business['type']) =>
  category.businessTypeMustBe == null || category.businessTypeMustBe === type;
