import classNames from 'classnames';
import Link from 'next/link';
import { Business } from '../../backend/db/all-entities';
import { PRICING_TIERS } from '../../policy/business-pricing-tiers';
import transformImageUrl from '../../utils/image-transformation';

interface Props {
  className?: string;
  imageSizeClass?: string;
  narrowSpacing?: boolean;
  business: Pick<Business, 'id' | 'name' | 'logoUrl' | 'pricingPlan'>;
}

export const MAX_REASONABLE_SIZE = 64;

const BusinessProfileHeader: React.FC<Props> = ({
  business,
  className,
  imageSizeClass: imageClass = 'w-8 h-8',
  narrowSpacing
}) => (
  <Link href={`/businesses/${business.id}`}>
    <a className={classNames('flex items-center', className)}>
      {business.logoUrl && PRICING_TIERS[business.pricingPlan].logoVisible && (
        <img
          src={transformImageUrl(business.logoUrl, {
            width: MAX_REASONABLE_SIZE,
            height: MAX_REASONABLE_SIZE,
            rounded: true
          })}
          alt='Logo'
          className={classNames('rounded-full shadow-inner', narrowSpacing ? 'mr-1' : 'mr-2', imageClass)}
        />
      )}
      {business.name}
    </a>
  </Link>
);

export default BusinessProfileHeader;
