import { Business } from '../backend/db/all-entities';

interface PricingPlan {
  tierNumber: number;
  stripePriceId?: string;
  logoVisible: boolean;
  canCreateSpecialsInMonth: (specialMonth: number, isGym: boolean) => boolean;
  specialMonths: number;
  maxGeolocations: number;
}

const isBetweenMarchAndOctober = (month: number) => month % 12 >= 3 && month % 12 <= 10;
export const restrictGymsToBetweenMarchAndOctober = (specialMonth: number, isGym: boolean) =>
  isGym ? isBetweenMarchAndOctober(specialMonth) : true;

export const PRICING_TIERS: Record<Business['pricingPlan'], PricingPlan> = {
  free: {
    tierNumber: 0,
    logoVisible: false,
    canCreateSpecialsInMonth: () => false,
    specialMonths: 0,
    maxGeolocations: 1
  },
  bronze: {
    tierNumber: 1,
    stripePriceId: process.env.STRIPE_BUSINESS_BRONZE_PRICING_PLAN_PRICE_ID,
    logoVisible: true,
    canCreateSpecialsInMonth: restrictGymsToBetweenMarchAndOctober,
    specialMonths: 3,
    maxGeolocations: 5
  },
  silver: {
    tierNumber: 2,
    stripePriceId: process.env.STRIPE_BUSINESS_SILVER_PRICING_PLAN_PRICE_ID,
    logoVisible: true,
    canCreateSpecialsInMonth: restrictGymsToBetweenMarchAndOctober,
    specialMonths: 6,
    maxGeolocations: 8
  },
  gold: {
    tierNumber: 3,
    stripePriceId: process.env.STRIPE_BUSINESS_GOLD_PRICING_PLAN_PRICE_ID,
    logoVisible: true,
    canCreateSpecialsInMonth: () => true,
    specialMonths: 12,
    maxGeolocations: 10
  }
};
