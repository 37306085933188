import { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { Special } from '../backend/db/all-entities';
import { useRequest } from '../utils/make-api-request';
import BusinessProfileHeader from './business/profile-header';

/**
 * Like the % operator, but wraps negative numbers as well as those above `m`.
 */
function modulo(n: number, m: number) {
  return ((n % m) + m) % m;
}

const BACK_FORWARD_BUTTON =
  'w-8 h-8 flex items-center justify-center hover:bg-blue-800 touched:bg-blue-800 focus:bg-blue-800 focus:outline-none';

const Banner: React.FC = () => {
  const [{ data: banner }, makeRequest] = useRequest<'get', Special[]>('banner', 'get');
  const [index, setIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(-1);
  const moveBy = (steps: number) => {
    if (banner != null) {
      setIndex(i => modulo(i + steps, banner.length));
      window.clearInterval(intervalId);
    }
  };
  const forward = () => moveBy(1);
  const backward = () => moveBy(-1);
  useEffect(() => {
    // a second variable is here in order to not include intervalId as a effect dependency
    let intervalIdInClosure = -1;
    makeRequest().then(specials => {
      intervalIdInClosure = window.setInterval(() => {
        setIndex(i => modulo(i + 1, specials.length));
      }, 3000);
      setIntervalId(intervalIdInClosure);
    });
    return () => window.clearInterval(intervalIdInClosure);
  }, [makeRequest]);
  return (
    <div className='flex items-center justify-between h-8 bg-blue-900'>
      <button onClick={backward} className={BACK_FORWARD_BUTTON} aria-label='Previous'>
        <ChevronLeft />
      </button>
      {banner && banner.length > 0 && (
        // Add tabIndex to make element scrollable
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        <div className='flex flex-1 whitespace-no-wrap overflow-x-auto items-center banner-content' tabIndex={0}>
          {banner[index].text} &mdash;&nbsp;
          <BusinessProfileHeader business={banner[index].business} imageSizeClass='w-6 h-6' narrowSpacing />
        </div>
      )}
      <button onClick={forward} className={BACK_FORWARD_BUTTON} aria-label='Next'>
        <ChevronRight />
      </button>
      <style jsx>{`
        .banner-content {
          @apply mx-auto;
          max-width: max-content;
        }
      `}</style>
    </div>
  );
};

export default Banner;
