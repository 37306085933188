interface TransformationOptions {
  /**
   * Width of the image. If height is also provided, image will behave like object-fit: cover.
   */
  width?: number;
  /**
   * Height of the image. If width is also provided, image will behave like object-fit: cover.
   */
  height?: number;
  /**
   * Fully rounds corners of the image
   */
  rounded?: boolean;
}

/**
 * Transforms the url of a cloudinary image by setting parameters on the URL. If the image is not from cloudinary,
 * no changes are applied.
 *
 * @param imageUrl
 * @param options
 */
export default function transformImageUrl(imageUrl: string, { width, height, rounded }: TransformationOptions) {
  const match = imageUrl.match(/^https:\/\/res.cloudinary.com\/(?:[a-zA-Z0-9_-]+)\/image\/(?:[a-zA-Z0-9_-]+)\/?/);
  if (match == null) return imageUrl;
  const baseUrl = match[0]; // e.g. https://res.cloudinary.com/fit-society/image/upload/
  const imageIdentifier = imageUrl.slice(baseUrl.length); // e.g. v23872593852/f9a8fj329j3f.jpg or 2389jfok2j3523.jpg
  const transformations = [
    width && `w_${width}`,
    height && `h_${height}`,
    (width || height) && 'c_fill',
    rounded && 'r_max',
    typeof window !== 'undefined' && window.devicePixelRatio && `dpr_${window.devicePixelRatio}`
  ]
    .filter((x): x is string => !!x)
    .join(',');
  const originalExtension = imageIdentifier.slice(imageIdentifier.indexOf('.')); // .png, .jpg
  const preferredExtension = rounded ? '.png' : originalExtension;
  const imageIdentifierWithoutExtension = imageIdentifier.slice(0, imageIdentifier.indexOf('.')); // no dot
  return `${baseUrl}${transformations}/${imageIdentifierWithoutExtension}${preferredExtension}`;
}
