import { NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useEffect } from 'react';
import { Business } from '../backend/db/all-entities';
import { CATEGORIES } from '../misc-types/category';
import Banner from '../react-components/banner';
import Footer from '../react-components/footer';
import TopNavigationBar from '../react-components/top-navigation-bar';
import transformImageUrl from '../utils/image-transformation';
import { useRequest } from '../utils/make-api-request';

const Index: NextPage = () => {
  const [sponsors, loadSponsors] = useRequest<'get', Array<Pick<Business, 'id' | 'name' | 'logoUrl'>>>(
    'sponsor-list',
    'get'
  );
  useEffect(() => {
    loadSponsors();
  }, [loadSponsors]);
  return (
    <div>
      <Head>
        <title>FitSociety</title>
        <meta
          name='description'
          content='Browse trainers, nutrition, apparel, and more near you without creating an account or
              hassling with payment info. It&rsquo;s free and always will be.'
        />
      </Head>
      <Banner />
      <TopNavigationBar />
      <div className='relative bg-gray-800 overflow-hidden'>
        <div className='max-w-screen-xl mx-auto'>
          <div className='relative z-10 pb-8 bg-gray-800 sm:pb-16 md:pb-20 md:max-w-2xl md:w-3/5 lg:pb-28 xl:pb-32'>
            <svg
              className='hidden md:block absolute right-0 inset-y-0 h-full w-32 text-gray-800 transform translate-x-1/2'
              style={{ zIndex: -1 }}
              fill='currentColor'
              viewBox='0 0 100 100'
              preserveAspectRatio='none'
            >
              <polygon points='50,0 100,0 50,100 0,100' />
            </svg>
            <section className='text-center sm:text-left mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8'>
              <h1 className='leading-tight'>ALL THINGS FIT</h1>
              <hr className='my-2 w-16 border-blue-600 border-2 mx-auto sm:mx-0' />
              <p className='my-4'>
                Browse trainers, nutrition, apparel, and more near you without creating an account or hassling with
                payment info. It&rsquo;s free and always will be.
              </p>
              <h2 id='categories'>Categories</h2>
              <div className='categories my-4'>
                {CATEGORIES.map(({ slug, name }) => (
                  <Link href='/find/[slug]' as={`/find/${slug}`} key={name}>
                    <a>
                      <span className='text-white leading-snug'>{name}</span>
                    </a>
                  </Link>
                ))}
                <Link href='/find/[slug]' as='/find/specials'>
                  <a>
                    <span className='text-white leading-snug'>Specials</span>
                  </a>
                </Link>
              </div>
            </section>
          </div>
        </div>
        <div className='hidden md:block absolute inset-y-0 right-0 w-1/2' id='hero-image' />
      </div>
      <div className='mx-8 my-8 sm:mx-12 lg:mx-16'>
        <h2 id='sponsors'>Sponsors</h2>
        {sponsors.data &&
          (sponsors.data.length > 0 ? (
            <section className='sponsors'>
              {sponsors.data.map(({ id, name, logoUrl }) => (
                // eslint-disable-next-line react/no-array-index-key
                <Link href='/businesses/[id]' as={`/businesses/${id}`} key={id}>
                  <a className='bg-gray-700 rounded p-2'>
                    {logoUrl ? (
                      <img
                        src={transformImageUrl(logoUrl, { width: 80, height: 80, rounded: true })}
                        alt='Logo'
                        className='w-20 h-20 block mx-auto rounded-full shadow-inner'
                      />
                    ) : (
                      <div className='w-20 h-20' />
                    )}
                    <div className='text-center leading-none mt-2'>{name}</div>
                  </a>
                </Link>
              ))}
            </section>
          ) : (
            <div className='my-2 text-center'>Nobody is a sponsor yet.</div>
          ))}
      </div>
      <Footer />
      <style jsx>{`
        @screen md {
          #hero-image {
            background-image: url('/images/hero.webp');
            background-size: cover;
            background-position: 50% 20%;
          }
          #hero-image::before {
            background-color: theme('colors.white');
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            mix-blend-mode: darken;
            position: absolute;
            top: 0;
            left: 0;
          }
          #hero-image::after {
            background-color: theme('colors.gray.900');
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            mix-blend-mode: lighten;
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        .categories {
          display: grid;
          grid-gap: 0.5rem;
          grid-template-columns: repeat(auto-fit, 120px);
          grid-auto-rows: 120px;
          justify-content: center;
        }
        .categories > a {
          @apply flex rounded justify-center text-center items-center p-4 select-none text-sm uppercase font-bold;
          background-image: url('/images/shield.png');
          background-size: cover;
          text-shadow: 1px 1px 1px #0005, -1px 1px 1px #0005, 1px -1px 1px #0005, -1px -1px 1px #0005;
        }
        .categories > a span {
          transform: translateY(-1rem);
        }
        .categories > a:hover,
        .categories > a:focus,
        .categories > a:active {
          filter: brightness(1.2);
        }
        h2 {
          @apply text-center my-6;
        }
        @screen sm {
          h2 {
            @apply my-8;
          }
        }

        .sponsors {
          display: grid;
          justify-content: center;
          grid-gap: 0.5rem;
          grid-template-columns: repeat(auto-fit, 128px);
        }
      `}</style>
    </div>
  );
};
export default Index;
