import { Facebook, Instagram } from 'react-feather';

const TikTok = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' fill='currentColor' viewBox='0 0 16 16'>
    <path d='M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z' />
  </svg>
);

const socialLinks = [
  {
    icon: Facebook,
    url: 'https://www.facebook.com/FitSocietycom-112678627116898/'
  },
  // {
  //   icon: Twitter,
  //   url: 'https://twitter.com'
  // },
  {
    icon: Instagram,
    url: 'https://instagram.com/fitsociety.com_/'
  },
  {
    icon: TikTok,
    url: 'https://www.tiktok.com/@achilliesroyalt?_t=8fRibiR3i8o&_r=1'
  }
];

const Footer: React.FC = () => (
  <footer className='bg-gray-900 flex-shrink-0 flex px-4 py-2 select-none'>
    <span className='mr-auto'>FITSOCIETY.COM</span>
    {socialLinks.map(({ icon: Icon, url }) => (
      <a href={url} key={url} className='ml-2'>
        <Icon />
      </a>
    ))}
  </footer>
);

export default Footer;
